import { NavLink } from "react-router-dom";

export function NavbarLink({ link, toggle, children, ml = 0 }) {
  const _ml = ml ? "ml-4" : "";
  return (
    <NavLink
      to={link}
      onClick={toggle}
      className={({ isActive }) =>
        `px-1 w-[150px] hover:bg-sky-500 ${_ml} transition rounded-sm ${
          isActive ? "bg-sky-500" : ""
        }`
      }
    >
      {children}
    </NavLink>
  );
}
