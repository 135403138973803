import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { NavbarLink } from "./NavbarLink";

function Navbar({ toggle, opened }) {
  const { auth } = useContext(AuthContext);
  const NormalL = auth.user.permissions.includes("NormalL");

  return (
    <div className="flex flex-col font-bold relative gap-3">
      {NormalL ? (
        <NormalLNavbar toggle={toggle} />
      ) : (
        <NormalBNavbar toggle={toggle} />
      )}
    </div>
  );
}

export default Navbar;

function NormalLNavbar({ toggle }) {
  return (
    <>
      <NavbarLink link="/l" toggle={toggle}>
        Acasa
      </NavbarLink>
      <NavbarLink link="/l/create" toggle={toggle}>
        Creeaza transport
      </NavbarLink>
      <NavbarLink link="/l/history" toggle={toggle}>
        Istoric
      </NavbarLink>
    </>
  );
}

function NormalBNavbar({ toggle }) {
  return (
    <>
      <NavbarLink link="/b" toggle={toggle}>
        Acasa
      </NavbarLink>
      <NavbarLink link="/b/create" toggle={toggle}>
        Creeaza transport
      </NavbarLink>
    </>
  );
}
