import { Button } from "@mantine/core";
import { Link, useRouteError } from "react-router-dom";

function RouterError() {
  const error = useRouteError();

  return (
    <div
      id="app_error"
      className=" h-screen flex flex-col gap-3 items-center pt-20"
    >
      <h1 className="font-bold text-3xl">Eroare</h1>
      <p>
        {error.status === 404
          ? "404 - Resursa accesata nu exista"
          : `${error.status} - ${error.statusText}`}
      </p>
      <Link to="/">
        <Button>Meniu</Button>
      </Link>
    </div>
  );
}

export default RouterError;
