import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { API_URL, isExpiredJWT } from "../utils";

export default function useRute() {
  const [rute, setRute] = useState([]);
  const { auth, refresh } = useContext(AuthContext);
  const isAdmin = auth.user.permissions.includes("Admin");

  useEffect(() => {
    let accessToken = auth.aT;

    async function refreshExpiredAT() {
      accessToken = await refresh();
    }

    async function getRute() {
      try {
        const { data } = await axios.get(API_URL + "/api/l/rute/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (isAdmin) {
          const ruteArr = [];
          for (const key in data)
            ruteArr.push({ number: key, value: data[key] });
          setRute(ruteArr);
        } else setRute(data);
      } catch (err) {
        console.error(err);
      }
    }

    async function init() {
      if (isExpiredJWT(auth.user.exp)) await refreshExpiredAT();
      await getRute();
    }

    init();
  }, [refresh, auth.aT, auth.user.exp, isAdmin]);

  return rute;
}
