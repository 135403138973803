import { useContext, useState } from "react";
import CustomError from "./CustomError";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../context/AuthContext";
import { Button, Table } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { API_URL, formatDate, isExpiredJWT } from "../utils";
import axios from "axios";

function HistoryL() {
  const { auth, refresh } = useContext(AuthContext);
  const [transports, setTransports] = useState([]);
  const [err, setErr] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start: "",
      end: "",
    },
  });

  async function onSubmit(data) {
    const added_by_user = auth.user.name;
    const start = data.start.toISOString();
    const end = data.end.toISOString();
    const q =
      API_URL +
      `/api/l/transport/history/?pending=false&added_by_user=${added_by_user}&start=${start}&end=${end}`;

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      const res = await axios.get(q, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setTransports(res.data);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  }

  return err ? (
    <CustomError error={err} />
  ) : (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[400px] mb-6 flex flex-col gap-2"
      >
        <Controller
          name="start"
          rules={{ required: true || "Ora de inceput este obligatorie" }}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label="Inceput"
              error={errors.start ? errors.start.message : ""}
              withAsterisk
              clearable
              placeholder="Selecteaza data si ora de inceput"
            />
          )}
        />
        <Controller
          name="end"
          rules={{ required: true || "Ora de sfarsit este obligatorie" }}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label="Sfarsit"
              error={errors.end ? errors.end.message : ""}
              withAsterisk
              clearable
              placeholder="Selecteaza data si ora de sfarsit"
            />
          )}
        />
        <Button
          type="submit"
          className="!w-[125px] mt-2"
          onClick={handleSubmit(onSubmit)}
        >
          Trimite
        </Button>
      </form>
      {transports.length ? <MantineTable transports={transports} /> : ""}
    </div>
  );
}

export default HistoryL;

function MantineTable({ transports }) {
  const rows = transports.map((elem) => (
    <Table.Tr key={elem.date}>
      <Table.Td>{formatDate(elem.date)}</Table.Td>
      <Table.Td>{elem.truck}</Table.Td>
      <Table.Td>{elem.tur}</Table.Td>
      <Table.Td>{elem.ruta.join("-")}</Table.Td>
      <Table.Td>{elem.kms}</Table.Td>
      <Table.Td>{elem.time}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Data</Table.Th>
            <Table.Th>Camion</Table.Th>
            <Table.Th>Tur</Table.Th>
            <Table.Th>Ruta</Table.Th>
            <Table.Th>Kilometri</Table.Th>
            <Table.Th>Durata</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
