import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { formatName } from "../utils";
import { Button } from "@mantine/core";

function Header() {
  const { auth, logout } = useContext(AuthContext);

  function handleLogout(e) {
    e.preventDefault();
    logout();
  }

  return (
    <div className="flex justify-start gap-6 items-center w-full">
      <p className="font-bold text-lg">{formatName(auth.user.name)}</p>
      <form action="/logout" method="post" onSubmit={handleLogout}>
        <Button onClick={handleLogout} variant="filled">
          Logout
        </Button>
      </form>
    </div>
  );
}

export default Header;
