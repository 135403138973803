import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import {
  API_URL,
  formatDate,
  formatName,
  INCARCARE_B,
  isExpiredJWT,
  LIVRARE_B,
  TRAILERS_B,
  TRUCKS_B,
} from "../utils";
import useFeedback from "../hooks/useFeedback";
import { Controller, useForm } from "react-hook-form";
import { Button, NumberInput, Select, TextInput } from "@mantine/core";

function ShowTransportB() {
  const { auth, refresh } = useContext(AuthContext);
  const { transportId } = useParams();
  const [transport, setTransport] = useState(null);

  useEffect(() => {
    async function getTransport() {
      try {
        const { data } = await axios.get(
          API_URL + `/api/b/transport/${transportId}`,
          {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }
        );
        setTransport(data);
      } catch (err) {
        console.error(err);
      }
    }

    if (auth.aT && !isExpiredJWT(auth.user.exp)) getTransport();
    else refresh();
  }, [auth, refresh, transportId]);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-2">Vizualizare Transport</h1>
      {transport && <UpdateTransportBForm transport={transport} />}
    </div>
  );
}

function UpdateTransportBForm({ transport }) {
  const { auth, refresh } = useContext(AuthContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const setFeedback = useFeedback();

  const errorCfg = {
    truck: { func: (v) => TRUCKS_B.includes(v), msg: "Numar camion invalid" },
    trailer: {
      func: (v) => TRAILERS_B.includes(v),
      msg: "Numar remorca invalid",
    },
    loc_incarcare: {
      func: (v) => INCARCARE_B.includes(v),
      msg: "Adresa de incarcare invalida",
    },
    loc_livrare: {
      func: (v) => LIVRARE_B.includes(v),
      msg: "Adresa de livrare invalida",
    },
    aviz_interbulk: { func: (v) => v > 0, msg: "Aviz Interbulk invalid" },
    aviz_safirtrans: { func: (v) => v > 0, msg: "Aviz Safirtrans invalid" },
    cantitate: { func: (v) => v > 0, msg: "Cantitate invalida" },
    sort: {
      func: (v) => ["0/1", "0/4", "4/8"].includes(v),
      msg: "Sort invalid",
    },
    price: { func: (v) => v >= 0, msg: "Pret invalid" },
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: formatDate(transport.date),
      added_by_user: formatName(transport.added_by_user.name),
      truck: transport.truck,
      trailer: transport.trailer,
      loc_incarcare: transport.loc_incarcare,
      loc_livrare: transport.loc_livrare,
      aviz_interbulk: transport.aviz_interbulk,
      aviz_safirtrans: transport.aviz_safirtrans,
      cantitate: transport.cantitate,
      sort: transport.sort,
      price: transport.price,
    },
  });

  async function handleUpdate(data) {
    data.sort = data.sort.split("/").join("-");
    data.pending = false;
    data.added_by_user = transport.added_by_user._id;
    data.date = transport.date;

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(
        API_URL + `/api/b/transport/${transport._id}/update`,
        data,
        { headers: { Authorization: `Bearer ${aT}` } }
      );
      setFeedback({
        type: "Success",
        active: true,
        msg: "Transportul a fost actualizat cu succes.",
      });
      navigate("/b/list", { state });
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Transportul nu a putut fi actualizat.",
      });
    }
  }

  async function handleDelete() {
    if (window.confirm("Confirma STERGERE transport.")) {
      const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
      try {
        await axios.post(
          API_URL + `/api/b/transport/${transport._id}/delete`,
          {},
          { headers: { Authorization: `Bearer ${aT}` } }
        );
        setFeedback({
          type: "Success",
          active: true,
          msg: "Transportul a fost sters cu succes.",
        });
        navigate("/b/list", { state });
      } catch (err) {
        console.error(err);
        setFeedback({
          type: "Error",
          active: true,
          msg: "Transportul nu a putut fi sters.",
        });
      }
    }
  }

  return (
    <form method="post" className="max-w-[400px] flex flex-col gap-1">
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <TextInput {...field} disabled label="Data" withAsterisk />
        )}
      />
      <Controller
        name="added_by_user"
        control={control}
        render={({ field }) => (
          <TextInput {...field} disabled label="Utilizator" withAsterisk />
        )}
      />
      <Controller
        name="truck"
        control={control}
        rules={{
          validate: (val) => errorCfg.truck.func(val) || errorCfg.truck.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Camion"
            placeholder="Alege camion..."
            error={errors.truck ? errors.truck.message : ""}
            data={TRUCKS_B}
            searchable
            withAsterisk
          />
        )}
      />
      <Controller
        name="trailer"
        control={control}
        rules={{
          validate: (val) => errorCfg.trailer.func(val) || errorCfg.trailer.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Remorca"
            placeholder="Alege remorca..."
            data={TRAILERS_B}
            error={errors.trailer ? errors.trailer.message : ""}
            searchable
            withAsterisk
          />
        )}
      />
      <Controller
        name="loc_incarcare"
        control={control}
        rules={{
          validate: (val) =>
            errorCfg.loc_incarcare.func(val) || errorCfg.loc_incarcare.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Adresa de incarcare"
            placeholder="Adresa de incarcare..."
            nothingFoundMessage="Adresa invalida"
            withAsterisk
            searchable
            data={INCARCARE_B}
          />
        )}
      />
      <Controller
        name="loc_livrare"
        control={control}
        rules={{
          validate: (val) =>
            errorCfg.loc_livrare.func(val) || errorCfg.loc_livrare.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Adresa de livrare"
            placeholder="Adresa de livrare..."
            nothingFoundMessage="Adresa invalida"
            withAsterisk
            searchable
            data={LIVRARE_B}
          />
        )}
      />
      <Controller
        name="aviz_interbulk"
        control={control}
        rules={{
          validate: (val) =>
            errorCfg.aviz_interbulk.func(val) || errorCfg.aviz_interbulk.msg,
        }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Aviz Interbulk"
            error={errors.aviz_interbulk ? errors.aviz_interbulk.message : ""}
            placeholder="Aviz Interbulk..."
            min={1}
            withAsterisk
            decimalScale={0}
          />
        )}
      />
      <Controller
        name="aviz_safirtrans"
        control={control}
        rules={{
          validate: (val) =>
            errorCfg.aviz_safirtrans.func(val) || errorCfg.aviz_safirtrans.msg,
        }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Aviz Safirtrans"
            error={errors.aviz_safirtrans ? errors.aviz_safirtrans.message : ""}
            placeholder="Aviz Safirtrans..."
            min={1}
            withAsterisk
            decimalScale={0}
          />
        )}
      />
      <Controller
        name="cantitate"
        control={control}
        rules={{
          validate: (val) =>
            errorCfg.cantitate.func(val) || errorCfg.cantitate.msg,
        }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Cantitate"
            error={errors.cantitate ? errors.cantitate.message : ""}
            placeholder="Cantitate..."
            min={1}
            withAsterisk
          />
        )}
      />
      <Controller
        name="sort"
        control={control}
        rules={{
          validate: (v) => errorCfg.sort.func(v) || errorCfg.sort.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Sort"
            withAsterisk
            placeholder="Selecteaza sort..."
            nothingFoundMessage="Sort inexistent"
            searchable
            data={["0/1", "0/4", "4/8"]}
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        rules={{
          validate: (val) => errorCfg.price.func(val) || errorCfg.price.msg,
        }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Pret"
            error={errors.price ? errors.price.message : ""}
            placeholder="Pret transport..."
          />
        )}
      />
      <div className="flex justify-start gap-3 mt-2">
        <Button
          type="submit"
          color="green"
          onClick={handleSubmit(handleUpdate)}
        >
          {transport.pending === true ? "Accepta" : "Actualizeaza"}
        </Button>
        <Button color="red" onClick={handleSubmit(handleDelete)}>
          Sterge
        </Button>
      </div>
    </form>
  );
}

export default ShowTransportB;
