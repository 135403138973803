import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import DashboardL from "./components/DashboardL";
import LoginPage from "./components/LoginPage";
import PersistLogin from "./components/PersistLogin";
import CreateTransportL from "./components/CreateTransportL";
import CreateTransportB from "./components/CreateTransportB";
import ListTransports from "./components/ListTransports";
import ShowTransportL from "./components/ShowTransportL";
import Calculator from "./components/Calculator";
import AdminDashboardMain from "./components/AdminDashboardMain";
import {
  Alert,
  MantineProvider,
  MultiSelect,
  Select,
  createTheme,
} from "@mantine/core";
import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "dayjs/locale/ro";
import RouterError from "./components/RouterError";
import { DatesProvider } from "@mantine/dates";
import HistoryL from "./components/HistoryL";
import RuteL from "./components/RuteL";
import UsersL from "./components/UsersL";
import DashboardB from "./components/DashboardB";
import ShowTransportB from "./components/ShowTransportB";

const router = createBrowserRouter([
  {
    Component: Layout,
    errorElement: <RouterError />,
    children: [
      {
        path: "login",
        Component: LoginPage,
        errorElement: <RouterError />,
      },

      {
        path: "/",
        Component: PersistLogin,
        errorElement: <RouterError />,
        children: [
          {
            index: true,
            Component: AdminDashboardMain,
            errorElement: <RouterError />,
          },
          {
            path: "l",
            Component: DashboardL,
            errorElement: <RouterError />,
          },
          {
            path: "l/list",
            Component: ListTransports,
            errorElement: <RouterError />,
          },
          {
            path: "l/list/:transportId",
            Component: ShowTransportL,
            errorElement: <RouterError />,
          },
          {
            path: "l/create",
            Component: CreateTransportL,
            errorElement: <RouterError />,
          },
          {
            path: "l/calculator",
            Component: Calculator,
            errorElement: <RouterError />,
          },
          {
            path: "l/history",
            Component: HistoryL,
            errorElement: <RouterError />,
          },
          {
            path: "l/rute",
            Component: RuteL,
            errorElement: <RouterError />,
          },
          {
            path: "l/users",
            Component: UsersL,
            errorElement: <RouterError />,
          },
          {
            path: "b",
            Component: DashboardB,
            errorElement: <RouterError />,
          },
          {
            path: "b/list",
            Component: ListTransports,
            errorElement: <RouterError />,
          },
          {
            path: "b/list/:transportId",
            Component: ShowTransportB,
            errorElement: <RouterError />,
          },
          {
            path: "b/create",
            Component: CreateTransportB,
            errorElement: <RouterError />,
          },
          {
            path: "b/calculator",
            Component: Calculator,
            errorElement: <RouterError />,
          },
        ],
      },
    ],
  },
]);

const theme = createTheme({
  components: {
    Select: Select.extend({
      classNames: {
        dropdown: "!border-black",
      },
    }),
    MultiSelect: MultiSelect.extend({
      classNames: {
        dropdown: "!border-black",
      },
    }),
    Alert: Alert.extend({
      classNames: {
        root: "!fixed top-[30px] -translate-x-1/2 !left-1/2 z-[20000000] w-[250px]",
      },
    }),
  },
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <DatesProvider settings={{ timezone: "Europe/Bucharest", locale: "ro" }}>
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />
      </DatesProvider>
    </MantineProvider>
  );
}

export default App;
