import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Group,
  NumberInput,
  Radio,
  TagsInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../context/AuthContext";
import { API_URL, isExpiredJWT } from "../utils";
import useFeedback from "../hooks/useFeedback";

const errorCfg = {
  type: {
    func: (v) => ["Contract", "Suplimentar"].includes(v),
    msg: 'Valori acceptate: "Contract" / "Suplimentar"',
  },
  schimb_1: {
    func: (v) => v === true || v === false,
    msg: "Acest camp trebuie sa fie adevarat/fals",
  },
  schimb_2: {
    func: (v) => v === true || v === false,
    msg: "Acest camp trebuie sa fie adevarat/fals",
  },
  local: {
    func: (v) => v === true || v === false,
    msg: "Acest camp trebuie sa fie adevarat/fals",
  },
  taxa_acces: { func: (v) => v > 0, msg: "Taxa trebuie sa fie un numar >0" },
  // documente: { func: (v) => {}, msg: "" },
};

function UpdateAdminoptsForm({ adminopts }) {
  const [opened, { toggle }] = useDisclosure(false);
  const { auth, refresh } = useContext(AuthContext);
  const setFeedback = useFeedback();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: adminopts.type || "",
      schimb_1: adminopts.schimb_1,
      schimb_2: adminopts.schimb_2,
      local: adminopts.local || false,
      taxa_acces: adminopts.taxa_acces || null,
      documente: adminopts.documente || [],
      transport: adminopts.transport,
    },
  });

  async function onSubmit(data) {
    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(
        API_URL + `/api/l/adminopts/${adminopts.transport}/update`,
        data,
        { headers: { Authorization: `Bearer ${aT}` } }
      );
      setFeedback({
        type: "Success",
        active: true,
        msg: "Optiunile au fost actualizate cu succes.",
      });
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Optiunile nu au putut fi actualizate.",
      });
    }
  }

  return (
    <div>
      <Box mt={50}>
        <Group mb={5}>
          <Button onClick={toggle}>Mai multe</Button>
        </Group>

        <Collapse in={opened}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-4 flex flex-col gap-3 pb-8"
          >
            <Controller
              name="type"
              control={control}
              rules={{
                validate: (v) => errorCfg.type.func(v) || errorCfg.type.msg,
              }}
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  label="Tip transport"
                  error={errors.type ? errors.type.message : ""}
                >
                  <Group mt="xs">
                    <Radio value="Contract" label="Contract" />
                    <Radio value="Suplimentar" label="Suplimentar" />
                  </Group>
                </Radio.Group>
              )}
            />
            <Controller
              name="schimb_1"
              control={control}
              rules={{
                validate: (v) =>
                  errorCfg.schimb_1.func(v) || errorCfg.schimb_1.msg,
              }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  error={errors.schimb_1 ? errors.schimb_1.message : ""}
                  label="Schimb 1"
                />
              )}
            />
            <Controller
              name="schimb_2"
              control={control}
              rules={{
                validate: (v) =>
                  errorCfg.schimb_2.func(v) || errorCfg.schimb_2.msg,
              }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  error={errors.schimb_2 ? errors.schimb_2.message : ""}
                  label="Schimb 2"
                />
              )}
            />
            <Controller
              name="local"
              control={control}
              rules={{
                validate: (v) => errorCfg.local.func(v) || errorCfg.local.msg,
              }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  error={errors.local ? errors.local.message : ""}
                  label="Locala"
                />
              )}
            />
            <Controller
              name="taxa_acces"
              control={control}
              rules={{
                validate: (v) =>
                  errorCfg.taxa_acces.func(v) || errorCfg.taxa_acces.msg,
              }}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label="Taxa acces"
                  error={errors.taxa_acces ? errors.taxa_acces.message : ""}
                  placeholder="Valoare taxa acces..."
                />
              )}
            />
            <Controller
              name="documente"
              control={control}
              render={({ field }) => (
                <TagsInput
                  {...field}
                  label="Documente"
                  error={errors.documente ? errors.documente.message : ""}
                  placeholder="Adauga numar documente..."
                  clearable
                />
              )}
            />
            <Button
              type="submit"
              className="!w-[100px]"
              onClick={handleSubmit(onSubmit)}
            >
              Trimite
            </Button>
          </form>
        </Collapse>
      </Box>
    </div>
  );
}

export default UpdateAdminoptsForm;
