import { Alert } from "@mantine/core";

const defaultFeedback = {
  type: "",
  active: false,
  msg: "",
};

function Feedback({ feedback, setFeedback }) {
  return feedback.type === "Success" ? (
    <Alert
      variant="filled"
      color="green"
      withCloseButton
      onClose={() => setFeedback(defaultFeedback)}
      title="Succes"
    >
      {feedback.msg}
    </Alert>
  ) : (
    <Alert
      variant="filled"
      color="red"
      withCloseButton
      onClose={() => setFeedback(defaultFeedback)}
      title="Eroare"
    >
      {feedback.msg}
    </Alert>
  );
}

export default Feedback;
