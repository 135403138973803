import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

export default function useFeedback() {
  const [feedback, setFeedback] = useOutletContext();

  useEffect(() => {
    let id = -1;
    if (feedback.active) {
      id = setTimeout(() => {
        setFeedback({ type: "", active: false });
      }, 3000);
    }
    return () => {
      clearTimeout(id);
    };
  }, [feedback, setFeedback]);

  return setFeedback;
}
