import { Outlet } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";

function Layout() {
  return (
    <div id="app" className="relative">
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    </div>
  );
}

export default Layout;
