import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

function AdminDashboardMain() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const isAdmin = auth.user.permissions.includes("Admin");

  useEffect(() => {
    if (!isAdmin) {
      if (auth.user.permissions.includes("NormalL")) navigate("/l");
      else navigate("/b");
    }
  }, [auth.user.permissions, isAdmin, navigate]);

  if (!isAdmin) return;

  return (
    <div className="flex flex-col items-center">
      <h1 className="font-bold text-2xl mb-5">Meniu</h1>
      <div className="flex gap-5">
        <Card color="orange" link="/l">
          Lidl
        </Card>
        <Card color="green" link="/b">
          Basculante
        </Card>
      </div>
    </div>
  );
}

export default AdminDashboardMain;

function Card({ children, link, color }) {
  return (
    // rendering invisible elements to have colors at compile
    <>
      <span
        aria-hidden="true"
        className="bg-blue-300 hover:bg-blue-500 hidden "
      ></span>
      <span className="bg-orange-300 hover:bg-orange-500 hidden "></span>
      <span className="bg-green-300 hover:bg-green-500 hidden "></span>
      <span className="bg-rose-300 hover:bg-rose-500 hidden "></span>
      <Link
        to={link}
        className={`w-[200px] h-[75px] bg-${color}-300 hover:bg-${color}-500 rounded font-bold flex items-center justify-center transition`}
      >
        {children}
      </Link>
    </>
  );
}
