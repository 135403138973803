import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import {
  Button,
  Modal,
  PasswordInput,
  SegmentedControl,
  Table,
  TextInput,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { API_URL, formatName, isExpiredJWT } from "../utils";
import { useNavigate } from "react-router-dom";
import useFeedback from "../hooks/useFeedback";
import { GrAdd } from "react-icons/gr";
import { TiDeleteOutline } from "react-icons/ti";

const errorCfg = {
  name: { func: (v) => v.length > 0, msg: "Introduceti nume utilizator" },
  pw: { func: (v) => v.length > 0, msg: "Introduceti parola" },
};

function UsersL() {
  const { auth, refresh } = useContext(AuthContext);
  const [opened, { open, close }] = useDisclosure(false);
  const setFeedback = useFeedback();
  const navigate = useNavigate();
  const [active, setActive] = useState("true");
  const [users, setUsers] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      pw: "",
    },
  });

  useEffect(() => {
    let accessToken = auth.aT;

    async function refreshExpiredAT() {
      accessToken = await refresh();
    }

    async function getUsers() {
      try {
        const { data } = await axios.get(
          API_URL + `/api/user/?active=${active}&normal=l`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        setUsers(data);
      } catch (err) {
        console.error(err);
      }
    }

    async function init() {
      if (isExpiredJWT(auth.user.exp)) await refreshExpiredAT();
      await getUsers();
    }

    init();
  }, [active, auth.aT, auth.user.exp, refresh]);

  async function handleCreate(data) {
    if (window.confirm(`Confirma adaugare utilizator: "${data.name}"`)) {
      const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
      try {
        await axios.post(API_URL + `/api/user/add`, data, {
          headers: { Authorization: `Bearer ${aT}` },
        });
        setFeedback({
          type: "Success",
          active: true,
          msg: "Utilizatorul a fost creat cu succes.",
        });
        navigate("/l/users");
        close();
      } catch (err) {
        console.error(err);
        setFeedback({
          type: "Error",
          active: true,
          msg: "Utilizatorul nu a putut fi creat.",
        });
      }
    }
  }

  async function handleUpdate(name) {
    const txt = active === "true" ? "dezactivare" : "activare";
    if (window.confirm(`Confirma ${txt} utilizator: "${formatName(name)}"`)) {
      const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
      try {
        await axios.post(
          API_URL + `/api/user/update`,
          { name: name },
          {
            headers: { Authorization: `Bearer ${aT}` },
          }
        );
        setFeedback({
          type: "Success",
          active: true,
          msg: "Utilizatorul a actualizat cu succes.",
        });
        navigate("/l/users");
        close();
      } catch (err) {
        console.error(err);
        setFeedback({
          type: "Error",
          active: true,
          msg: "Utilizatorul nu a putut fi actualizat.",
        });
      }
    }
  }

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl font-bold mb-4">Utilizatori</h1>
      <Button onClick={open} className="mb-4 max-w-24">
        Adauga
      </Button>

      <div>
        <SegmentedControl
          value={active}
          onChange={setActive}
          color="blue"
          data={[
            { label: "Activ", value: "true" },
            { label: "Inactiv", value: "false" },
          ]}
        />
      </div>

      <div className="w-[500px] mt-10">
        <MantineTable
          users={users}
          active={active}
          handleUpdate={handleUpdate}
        />
      </div>

      <Modal
        lockScroll={false}
        opened={opened}
        onClose={close}
        title="Adauga Utilizator"
        classNames={{
          title: "!text-2xl !font-bold",
          body: "flex flex-col gap-4",
        }}
      >
        <form>
          <Controller
            name="name"
            rules={{
              validate: (v) => errorCfg.name.func(v) || errorCfg.name.msg,
            }}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Utilizator"
                error={errors.name ? errors.name.message : ""}
                withAsterisk
                placeholder="nume.prenume"
              />
            )}
          />
          <Controller
            name="pw"
            control={control}
            rules={{ validate: (v) => errorCfg.pw.func(v) || errorCfg.pw.msg }}
            render={({ field }) => (
              <PasswordInput
                {...field}
                label="Parola"
                error={errors.pw ? errors.pw.message : ""}
                withAsterisk
                placeholder="safirb000trlnum000"
              />
            )}
          />
          <div className="flex gap-4 mt-4 justify-center">
            <Button
              type="submit"
              onClick={handleSubmit(handleCreate)}
              color="green"
            >
              Creeaza utilizator
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default UsersL;

function MantineTable({ users, active, handleUpdate }) {
  const rows = users.map((user) => (
    <Table.Tr key={user.name}>
      <Table.Td>{formatName(user.name)}</Table.Td>
      <Table.Td>
        {active === "true" ? (
          <TiDeleteOutline
            className="text-xl cursor-pointer text-red-600"
            onClick={() => {
              handleUpdate(user.name);
            }}
          />
        ) : (
          <GrAdd
            className="text-xl cursor-pointer text-green-600"
            onClick={() => {
              handleUpdate(user.name);
            }}
          />
        )}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing={"sm"}
        classNames={{ thead: "!sticky" }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Nume</Table.Th>
            <Table.Th className="w-[50px]">Edit</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
