import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import logo from "../media/logoSmall.svg";
import CustomError from "./CustomError";

const errorCfg = {
  name: { func: (v) => v.length > 0, msg: "Introduceti nume utilizator" },
  pw: { func: (v) => v.length > 0, msg: "Introduceti parola" },
};

function LoginPage() {
  const { login, auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [err, setErr] = useState(null);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      pw: "",
    },
  });

  useEffect(() => {
    if (auth.aT) {
      if (auth.user.permissions.includes("Admin")) navigate("/");
      else if (auth.user.permissions.includes("NormalL")) navigate("/l");
      else if (auth.user.permissions.includes("NormalB")) navigate("/b");
    }
  }, [auth, navigate]);

  function onSubmit(data) {
    const { name, pw } = data;
    login(name, pw)
      .then()
      .catch((err) => {
        if (!err.response) {
          setErr(err);
          return;
        }

        if (err.response.status === 429) {
          setErr(err);
          return;
        }

        if (err.response.status === 400)
          setError("name", {
            type: "server",
            message: "Utilizator incorect",
          });

        if (err.response.status === 403)
          setError("name", {
            type: "server",
            message: "Utilizator inactiv",
          });

        if (err.response.status === 401)
          setError("pw", { type: "server", message: "Parola incorecta" });
      });
  }

  return err ? (
    <CustomError error={err} />
  ) : (
    <div
      id="login_form"
      className="flex flex-col gap-6 justify-center my-20 items-center"
    >
      <img src={logo} className="max-w-[250px]" alt="safirtrans-logo" />
      <form
        method="post"
        className="w-[300px] flex flex-col gap-2 flex-1 p-4 rounded shadow border border-black/50"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="name"
          rules={{
            validate: (v) => errorCfg.name.func(v) || errorCfg.name.msg,
          }}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Utilizator"
              error={errors.name ? errors.name.message : ""}
              withAsterisk
              placeholder="Nume utilizator..."
            />
          )}
        />
        <Controller
          name="pw"
          control={control}
          rules={{ validate: (v) => errorCfg.pw.func(v) || errorCfg.pw.msg }}
          render={({ field }) => (
            <PasswordInput
              {...field}
              label="Parola"
              error={errors.pw ? errors.pw.message : ""}
              withAsterisk
              placeholder="Parola..."
            />
          )}
        />
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          Trimite
        </Button>
      </form>
    </div>
  );
}

export default LoginPage;
