import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Controller, useForm } from "react-hook-form";
import { Button, NumberFormatter, Select } from "@mantine/core";
import axios from "axios";
import {
  API_URL,
  downloadBlob,
  downloadCSV,
  isExpiredJWT,
  revertName,
} from "../utils";
import { DateTimePicker } from "@mantine/dates";
import CustomError from "./CustomError";
import { useLocation } from "react-router-dom";

function Calculator() {
  const { auth, refresh } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [err, setErr] = useState(null);
  const [transports, setTransports] = useState([]);
  const userNames = users.map((user) => user.formattedName);
  let location = useLocation();

  const path = location.pathname.match(/\/l\/.*/i) ? "l" : "b";

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      added_by_user: "",
      start: "",
      end: "",
    },
  });

  useEffect(() => {
    async function getUsers() {
      try {
        const res = await axios.get(API_URL + `/api/user/?normal=${path}`, {
          headers: { Authorization: `Bearer ${auth.aT}` },
        });
        setUsers(res.data);
      } catch (err) {
        setErr(err);
        console.error(err);
      }
    }

    if (auth.aT && !isExpiredJWT(auth.user.exp)) getUsers();
    else refresh();
  }, [auth, refresh, path]);

  async function onSubmit(data) {
    const added_by_user = revertName(data.added_by_user);
    const start = data.start.toISOString();
    const end = data.end.toISOString();
    const q =
      API_URL +
      `/api/${path}/transport/?pending=false&added_by_user=${added_by_user}&start=${start}&end=${end}`;

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      const res = await axios.get(q, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setTransports(res.data);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  }

  async function onSubmitCSV(data) {
    const start = data.start.toISOString();
    const end = data.end.toISOString();
    const q =
      API_URL +
      `/api/${path}/transport/csv/?pending=false&start=${start}&end=${end}`;

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      const res = await axios.get(q, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      const csvString = downloadCSV(res.data);
      downloadBlob(
        csvString,
        `${new Date().toDateString()}`,
        "text/csv;charset=utf-8;"
      );
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  }

  const sum = transports.length
    ? transports.reduce((acc, cur) => (acc += cur.price), 0)
    : 0;

  return err ? (
    <CustomError error={err} />
  ) : (
    <div>
      <h1 className="text-2xl font-bold mb-4">Calculator</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[400px] flex flex-col gap-2"
      >
        <Controller
          name="added_by_user"
          rules={{
            validate: (v) => userNames.includes(v) || "Campul este obligatoriu",
          }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Sofer"
              error={errors.added_by_user ? errors.added_by_user.message : ""}
              withAsterisk
              clearable
              placeholder="Selecteaza sofer..."
              nothingFoundMessage="Sofer inexistent"
              searchable
              data={users.map((user) => user.formattedName)}
            />
          )}
        />
        <Controller
          name="start"
          rules={{ required: true || "Ora de inceput este obligatorie" }}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label="Inceput"
              error={errors.start ? errors.start.message : ""}
              withAsterisk
              clearable
              placeholder="Data si ora..."
            />
          )}
        />
        <Controller
          name="end"
          rules={{ required: true || "Ora de sfarsit este obligatorie" }}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label="Sfarsit"
              error={errors.end ? errors.end.message : ""}
              withAsterisk
              clearable
              placeholder="Data si ora..."
            />
          )}
        />
        <Button
          type="submit"
          className="!w-[125px] mt-2"
          onClick={handleSubmit(onSubmit)}
        >
          Calculeaza
        </Button>
        <Button
          type="submit"
          className="!w-[125px] mt-2"
          onClick={handleSubmit(onSubmitCSV)}
        >
          CSV
        </Button>
      </form>
      <p className="mt-6 font-medium">
        {transports.length > 0 ? (
          <>
            <span>Total: </span>
            <NumberFormatter value={sum} suffix=" RON" thousandSeparator />
          </>
        ) : (
          "Niciun transport gasit pentru perioada selectata."
        )}
      </p>
    </div>
  );
}

export default Calculator;
