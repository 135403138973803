import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

function CustomError({ error }) {
  const { auth } = useContext(AuthContext);

  return (
    <div className="flex flex-col gap-2 items-center pt-20">
      <h1 className=" font-bold text-3xl">Eroare</h1>
      {error.response ? (
        <p className="font-bold">
          {error.response.status} - {error.response.statusText}
        </p>
      ) : (
        <p className="font-bold">503 - Server indisponibil</p>
      )}
      {auth.user ? (
        <Link to="/">
          <Button>Meniu</Button>
        </Link>
      ) : (
        <p>Incercati mai tarziu</p>
      )}
    </div>
  );
}

export default CustomError;
