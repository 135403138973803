import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { createContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils";

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    user: {},
    aT: "",
  });
  const navigate = useNavigate();

  async function login(_name, pw) {
    try {
      const { data } = await axios.post(
        API_URL + "/auth/login",
        { name: _name, pw: pw },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        }
      );
      const aT = data.accessToken;
      const user = jwtDecode(aT);
      setAuth({ user, aT });
    } catch (err) {
      setAuth({});
      console.error(err);
      throw err;
    }
  }

  async function logout() {
    await axios.post(API_URL + "/auth/logout", {}, { withCredentials: true });
    setAuth({});
    navigate("/login");
  }

  async function refresh() {
    try {
      const { data } = await axios.post(
        API_URL + "/auth/refresh",
        {},
        { withCredentials: true }
      );
      const aT = data.accessToken;
      const user = jwtDecode(aT);
      setAuth({ user, aT });
      return Promise.resolve(aT);
    } catch (err) {
      console.error(err);
      setAuth({});
      navigate("/login");
    }
  }

  const context = {
    auth,
    login,
    logout,
    refresh,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
